@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: rgb(255, 255, 255);
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  background-image: linear-gradient(45deg, #2897cb, rgb(255, 255, 255));
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(45deg, rgb(255, 255, 255), #2897cb);
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:active {
  background: linear-gradient(45deg, rgb(255, 255, 255), #2897cb);
  border-radius: 8px;
}
@layer components {
  /* group utilities */
  .containers {
    @apply xl:mx-40 lg:mx-20 mx-5;
  }
  .customBtn {
    @apply bg-[#01AEEF] text-white px-[1rem] py-[0.5rem] rounded-[4px] text-sm font-medium hover:bg-white hover:text-slate-950 border-[#01AEEF] border-y-2;
  }
  .customHeading {
    @apply lg:text-[2.7rem] text-[2rem] text-slate-950 font-semibold lg:leading-[3.5rem];
  }
  .customHeadingTwo {
    @apply xl:text-[1.6rem] lg:text-[1.6rem] text-[1.5rem] font-semibold lg:leading-[3.5rem] text-[#01AEEF];
  }
  .customPeragraph {
    @apply lg:text-[1.1rem] text-[1rem] text-slate-700 font-normal text-justify ;
  }
  .customPeragraphTwo {
    @apply lg:text-[1rem] text-[1rem] text-slate-950 font-bold text-justify;
  }
}


.react-pdf__Page__textContent {
  display: none;
}
.react-pdf__Page__annotations {
  display: none;
}
.react-pdf__Page__canvas {
  background: #b4b6b5;
  padding: 20px;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 95% !important;
  height: 100% !important;
}
